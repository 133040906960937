.loading-background {
    display: flex;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-3.5rem, -3.5rem);

        .spinner-icon {
            width: 7rem;
            height: 7rem;
            border-width: 8px;
        }
    }
}