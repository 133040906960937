@import '../../assets/variables';

#landing {
    font-family: $font-family-default;
    h2 {
        font-size: 2.5rem;
    }
    p {
        font-size: 1.25rem;
    }

    @media ( min-width: $screen-width-md ) {
        h2 {
            font-size: 3.5rem;
        }
        p {
            font-size: 1.75rem;
        }
    }

    .heroSegment {
        h2 {
            font-size: 3rem;
        }
        width: 100%;
        background-image:
            linear-gradient(
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.6)
            ), url('../../assets//images/landing-page/hollywood_production.jpg');
            background-size: auto 100%;
        background-position: center;

        @media ( min-width: $screen-width-sm ) {
            height: 380px;
            background-size: 100% auto;
        }

        @media ( min-width: $screen-width-md ) {
            height: 500px;
            h2 {
                font-size: 6rem;
            }
            p {
                font-size: 3rem;
            }
        }

        .title-logo {
            width: 250px;
            filter: invert(100%);

            @media ( min-width: $screen-width-md ) {
                width: 350px;
            }

            @media ( min-width: $screen-width-lg ) {
                width: 550px;
            }
        }
    }

    .discoverSegment {
        background-image:
            linear-gradient(
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.6)
            ), url('../../assets//images/landing-page/hero_sm.jpg');
            background-size: auto 100%;
        background-position: center;
        
        @media ( min-width: $screen-width-sm ) {
            height: 380px;
            background-size: 100% auto;
         }
    }

    .shareSegment {
        background-image:
            linear-gradient(
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.6)
            ), url('../../assets//images/landing-page/qrCode_sm.jpg');
            background-size: auto 100%;
        background-position: center;
        
        @media ( min-width: $screen-width-sm ) {
            height: 380px;
            background-size: 100% auto;
         }
    }

    .stepsImages {
        width: 80%;
    }

    .text-block {
        min-height: 350px;
        overflow: hidden;
        position: relative;

        @media ( min-width: $screen-width-lg ) {
            min-height: 400px;
        }   
    }

    .icon-image {
        filter: opacity(0.1);
    }
}
