@import '../../assets/variables';

#footer {
    
    .instagram-background {            
        background-image: linear-gradient(to top right, yellow , #E32EE8);;
        border-radius: $border-radius-sm;
    }

    .icon{
        height: 30px;
        min-width: 30px;
        filter: grayscale(40%);

        &.instagram-icon {
            filter: invert(100%);
            transform: scale(.7);
        }
    }

}