@import "../../assets/variables";

.app-body {
    font-family: $font-family-default;
    background-color: $bg-light;

    p, b, em, h1, h2, h3, h4, h5, label{
        color: $font-black-75;
    }

    .app-content {
        min-height: calc(100vh - 94px);
    }
}

@media print { 
    .app-body{
        background-color: unset;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #888;
}
