@import '../../assets/variables';

.ss-modal {
    display: flex;
    position: fixed;
    //z-index set to 1030 to counter bootstrap fixed nav.
    z-index: 1030;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);

     .ss-modal-content {
        font-family: $font-family-default;
        position: relative;
        background-color: $background-color-white;
        margin: auto;
        height: 100%;
        width: 100%;
        border-radius: $border-radius-md;
        box-shadow: $box-shadow-sm;
        overflow-y: auto;
        
        // hide scroll on modals
        &::-webkit-scrollbar {
            width: 0;
        }

        .ss-modal-body {
            position: unset;
            height: calc(100% - 100px);
            padding: 30px;
            overflow-y: scroll;
        }

        // sets up modal sizing
        &.Large{
            @media ( min-width: $screen-width-lg ) {
                height: 600px;
                width: 800px;

                .ss-modal-body {
                    max-height: 500px;
                }
            }

            @media ( max-height: 600px ) {
                height: 100%;
            }
        }

        &.FullScreen{
            @media ( min-width: $screen-width-lg ) {
                height: 90%;
                width: 90%;
            }
        }

        .ss-modal-header {
            position: relative;
            z-index: 1;
            padding: 10px 20px;
            height: 50px;
            box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);

            .ss-modal-close-btn {
                cursor: pointer;
                position: absolute;
                text-align: center;
                right: 10px;
                top: 10px;
                height: 30px;
                width: 30px;
                line-height: 0px;
                box-shadow: none;
                background-color: white;

                &:hover {
                    background-color: $highlight-color;
                    border-radius: 50%;
                }

                .ss-modal-close-icon {
                    line-height: 31px;
                    font-size: 25px;
                    color: $link-color;
                }
            }
        }

        .ss-modal-header-title {
            font-size: $font-size-xl;
        }

        .ss-modal-footer {
            background: white;
            height: 50px;
            width: 100%;
            position: absolute;
            bottom: 0;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        }

        .modal-back-btn {
            position: absolute;
            bottom: 6px;
            left: 10px;
            z-index: 1;
        }

        .modal-submit-btn {
            position: absolute;
            bottom: 6px;
            right: 10px;
            z-index: 1;
        }
    }
}

@media print {
    .ss-modal {
        .ss-modal-content {
            border-radius: unset;
        }
    }
}