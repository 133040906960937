@import "../../assets/variables";

#navigationBar {

    a, li {
        color:#f5f5f5;
        cursor: pointer;
    }

    a:hover {
        text-decoration: none;
        color:#c4c4c4;
    }

    .banner-logo-img {
        height: 35px;
        filter: invert(100%);
        
        @media ( min-width: $screen-width-lg ) {
            height: 35px;    
        }

    }

    .navigation-list {
        display: none;

        @media ( min-width: $screen-width-lg ) {
            display: block;
        }

    }

    .side-nav-menu {
        float: left;
        position: relative;
        z-index: 1;
        margin: 0 18px;
        height: 64px;
        line-height: 64px;

        @media ( min-width: $screen-width-lg ) {
            display: none;
        }
    }
}

