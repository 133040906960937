@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

// Screen Width //
$screen-width-sm: 576px;
$screen-width-md: 768px;
$screen-width-lg: 992px;
$screen-width-xl: 1200px;
$screen-width-xxl: 1400px;

$container-width-lg: 1080px;
$container-width-md: 860px;

// Font-family //
$font-family-default: 'Roboto';

// Font Size //

//default sizing
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 22px;

// Font Weight //
$font-weight-light: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font colors //
$font-black-90: #212121;
$font-black-75: #424242;
$font-black-60: #616161;
$font-black-55: #757575;

$highlight-color: #cfd8dc ;

$border-divider-color: #e0e0e0;


// Colors //
$background-color-white: #fefefe;
$background-color-light-grey: #eceff1;
$background-color-grey: #cfd8dc;
$background-color-blue: #0073b1;
$background-text: #F7FAFC;
$link-color: #0073b1;
$gold: #ffe57f;
$gold-dark: #ffd740;

$bg-light: #f8f9fa;

$grey: #90a4ae;
$grey-dark: #78909c;

// Border Radius //

$border-radius-sm: 4px;
$border-radius-md: 6px;


// Box Shadow //
$box-shadow-sm: 0 3px 10px rgba(0,0,0,.2);



// Bootstrap Overrides //
.btn-primary {
    background: #006097;
}

.btn-outline-primary {
    color:  #006097;
}