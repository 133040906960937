@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.modal-open {
    overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

.app-body {
  font-family: "Roboto";
  background-color: #f8f9fa; }
  .app-body p, .app-body b, .app-body em, .app-body h1, .app-body h2, .app-body h3, .app-body h4, .app-body h5, .app-body label {
    color: #424242; }
  .app-body .app-content {
    min-height: calc(100vh - 94px); }

@media print {
  .app-body {
    background-color: unset; } }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #888; }

.loading-background {
  display: flex;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); }
  .loading-background .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-3.5rem, -3.5rem);
            transform: translate(-3.5rem, -3.5rem); }
    .loading-background .spinner .spinner-icon {
      width: 7rem;
      height: 7rem;
      border-width: 8px; }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

.ss-modal {
  display: flex;
  position: fixed;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); }
  .ss-modal .ss-modal-content {
    font-family: "Roboto";
    position: relative;
    background-color: #fefefe;
    margin: auto;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto; }
    .ss-modal .ss-modal-content::-webkit-scrollbar {
      width: 0; }
    .ss-modal .ss-modal-content .ss-modal-body {
      position: unset;
      height: calc(100% - 100px);
      padding: 30px;
      overflow-y: scroll; }
    @media (min-width: 992px) {
      .ss-modal .ss-modal-content.Large {
        height: 600px;
        width: 800px; }
        .ss-modal .ss-modal-content.Large .ss-modal-body {
          max-height: 500px; } }
    @media (max-height: 600px) {
      .ss-modal .ss-modal-content.Large {
        height: 100%; } }
    @media (min-width: 992px) {
      .ss-modal .ss-modal-content.FullScreen {
        height: 90%;
        width: 90%; } }
    .ss-modal .ss-modal-content .ss-modal-header {
      position: relative;
      z-index: 1;
      padding: 10px 20px;
      height: 50px;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2); }
      .ss-modal .ss-modal-content .ss-modal-header .ss-modal-close-btn {
        cursor: pointer;
        position: absolute;
        text-align: center;
        right: 10px;
        top: 10px;
        height: 30px;
        width: 30px;
        line-height: 0px;
        box-shadow: none;
        background-color: white; }
        .ss-modal .ss-modal-content .ss-modal-header .ss-modal-close-btn:hover {
          background-color: #cfd8dc;
          border-radius: 50%; }
        .ss-modal .ss-modal-content .ss-modal-header .ss-modal-close-btn .ss-modal-close-icon {
          line-height: 31px;
          font-size: 25px;
          color: #0073b1; }
    .ss-modal .ss-modal-content .ss-modal-header-title {
      font-size: 20px; }
    .ss-modal .ss-modal-content .ss-modal-footer {
      background: white;
      height: 50px;
      width: 100%;
      position: absolute;
      bottom: 0;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); }
    .ss-modal .ss-modal-content .modal-back-btn {
      position: absolute;
      bottom: 6px;
      left: 10px;
      z-index: 1; }
    .ss-modal .ss-modal-content .modal-submit-btn {
      position: absolute;
      bottom: 6px;
      right: 10px;
      z-index: 1; }

@media print {
  .ss-modal .ss-modal-content {
    border-radius: unset; } }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

#navigationBar a, #navigationBar li {
  color: #f5f5f5;
  cursor: pointer; }

#navigationBar a:hover {
  text-decoration: none;
  color: #c4c4c4; }

#navigationBar .banner-logo-img {
  height: 35px;
  -webkit-filter: invert(100%);
          filter: invert(100%); }
  @media (min-width: 992px) {
    #navigationBar .banner-logo-img {
      height: 35px; } }

#navigationBar .navigation-list {
  display: none; }
  @media (min-width: 992px) {
    #navigationBar .navigation-list {
      display: block; } }

#navigationBar .side-nav-menu {
  float: left;
  position: relative;
  z-index: 1;
  margin: 0 18px;
  height: 64px;
  line-height: 64px; }
  @media (min-width: 992px) {
    #navigationBar .side-nav-menu {
      display: none; } }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

#footer .instagram-background {
  background-image: -webkit-gradient(linear, left bottom, right top, from(yellow), to(#E32EE8));
  background-image: -webkit-linear-gradient(bottom left, yellow, #E32EE8);
  background-image: linear-gradient(to top right, yellow, #E32EE8);
  border-radius: 4px; }

#footer .icon {
  height: 30px;
  min-width: 30px;
  -webkit-filter: grayscale(40%);
          filter: grayscale(40%); }
  #footer .icon.instagram-icon {
    -webkit-filter: invert(100%);
            filter: invert(100%);
    -webkit-transform: scale(0.7);
            transform: scale(0.7); }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

#landing {
  font-family: "Roboto"; }
  #landing h2 {
    font-size: 2.5rem; }
  #landing p {
    font-size: 1.25rem; }
  @media (min-width: 768px) {
    #landing h2 {
      font-size: 3.5rem; }
    #landing p {
      font-size: 1.75rem; } }
  #landing .heroSegment {
    width: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url(/static/media/hollywood_production.9602091a.jpg);
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/hollywood_production.9602091a.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/hollywood_production.9602091a.jpg);
    background-size: auto 100%;
    background-position: center; }
    #landing .heroSegment h2 {
      font-size: 3rem; }
    @media (min-width: 576px) {
      #landing .heroSegment {
        height: 380px;
        background-size: 100% auto; } }
    @media (min-width: 768px) {
      #landing .heroSegment {
        height: 500px; }
        #landing .heroSegment h2 {
          font-size: 6rem; }
        #landing .heroSegment p {
          font-size: 3rem; } }
    #landing .heroSegment .title-logo {
      width: 250px;
      -webkit-filter: invert(100%);
              filter: invert(100%); }
      @media (min-width: 768px) {
        #landing .heroSegment .title-logo {
          width: 350px; } }
      @media (min-width: 992px) {
        #landing .heroSegment .title-logo {
          width: 550px; } }
  #landing .discoverSegment {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url(/static/media/hero_sm.ebc2c2e8.jpg);
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/hero_sm.ebc2c2e8.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/hero_sm.ebc2c2e8.jpg);
    background-size: auto 100%;
    background-position: center; }
    @media (min-width: 576px) {
      #landing .discoverSegment {
        height: 380px;
        background-size: 100% auto; } }
  #landing .shareSegment {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url(/static/media/qrCode_sm.73796fcb.jpg);
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/qrCode_sm.73796fcb.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/qrCode_sm.73796fcb.jpg);
    background-size: auto 100%;
    background-position: center; }
    @media (min-width: 576px) {
      #landing .shareSegment {
        height: 380px;
        background-size: 100% auto; } }
  #landing .stepsImages {
    width: 80%; }
  #landing .text-block {
    min-height: 350px;
    overflow: hidden;
    position: relative; }
    @media (min-width: 992px) {
      #landing .text-block {
        min-height: 400px; } }
  #landing .icon-image {
    -webkit-filter: opacity(0.1);
            filter: opacity(0.1); }

.btn-primary {
  background: #006097; }

.btn-outline-primary {
  color: #006097; }

